import { UserRole } from '@prisma/client'

export function roleDisplay(role: UserRole) {
  const mapping: Record<UserRole, string> = {
    ADMIN: 'Admin',
    INVESTIGATOR: 'Investigator',
    QC: 'QC',
    FINDLAY_ELIGIBILITY: 'Findlay Eligibility',
  }

  return mapping[role]
}
