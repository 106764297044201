'use client'

import { PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { trpc } from '@/app/trpc/webClient'
import { useSession } from 'next-auth/react'

const LOCAL_STORAGE_STAGE_KEY = 'BENIQ_SST_STAGE_CACHE'

const saveSstStageToLocalStorage = (stage: string) => localStorage.setItem(LOCAL_STORAGE_STAGE_KEY, stage)

const getSstStageFromLocalStorage = () =>
  (process.env.SST_STAGE || localStorage.getItem(LOCAL_STORAGE_STAGE_KEY)) ?? undefined

const useSstStage = () => {
  const isAuthenticated = useSession().status === 'authenticated'
  const { data: sstStage } = trpc.getSstStage.useQuery(undefined, { enabled: isAuthenticated })

  useEffect(
    function _saveSstStageToLocalStorage() {
      if (!sstStage) return

      saveSstStageToLocalStorage(sstStage)
    },
    [sstStage],
  )

  if (typeof window === 'undefined') return process.env.SST_STAGE

  return sstStage || getSstStageFromLocalStorage()
}

const SstStageContext = createContext<ReturnType<typeof useSstStage> | null>(null)

const SstStageProvider = ({ children }: PropsWithChildren) => {
  const stage = useSstStage()

  return <SstStageContext.Provider value={stage}>{children}</SstStageContext.Provider>
}

const useSstStageContext = () => {
  const context = useContext(SstStageContext)

  if (!context) {
    throw new Error('useSstStageContext must be used within a SstStageProvider')
  }

  return context
}

export { useSstStageContext as useSstStage, SstStageProvider, getSstStageFromLocalStorage }
