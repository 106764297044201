'use client'

import { LogoutButton } from './LogoutButton'
import { Session } from 'next-auth'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/DropdownMenu'
import { Button } from '@/components/ui/Button'
import { UserCircle2Icon } from 'lucide-react'
import { roleDisplay } from '@core/domain/auth/roles/roleDisplay'

interface Props {
  session: Session
  className?: string
}

export const Authentication = ({ session, className }: Props) => {
  const rolesJoined = session.user.roles.map(roleDisplay).join(', ')

  return (
    <div className={className}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="icon" className="overflow-hidden rounded-full border-none">
            <UserCircle2Icon className="w-full h-full" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>
            <div className="flex flex-col gap-1">
              <span>{session.user.name}</span>
              <span className="text-sm font-normal text-xs">{rolesJoined}</span>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="p-0">
            <LogoutButton className="w-full flex items-start py-1 px-2" />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
