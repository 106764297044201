'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink } from '@trpc/client'
import React, { PropsWithChildren, useMemo } from 'react'
import SuperJSON from 'superjson'
import { getWindow } from '@/lib/utils'
import { trpc } from './webClient'

export const TrpcProvider = ({ children }: PropsWithChildren) => {
  const queryClient = useMemo(() => new QueryClient(), [])

  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        links: [
          httpBatchLink({
            url: `${getWindow()?.location.origin}/api/trpc`,
            transformer: SuperJSON,
            maxItems: 2,
          }),
        ],
      }),
    [],
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
