'use client'

import { useEffect } from 'react'
import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import { useSession } from 'next-auth/react'

const RUM_ID = process.env.NEXT_PUBLIC_RUM_ID ?? ''
const RUM_REGION = process.env.NEXT_PUBLIC_REGION ?? ''
const RUM_IDENTITY_POOL_ID = process.env.NEXT_PUBLIC_RUM_POOL_ID ?? ''

let awsRum: AwsRum | undefined

export function RumProvider({ children }: { children: React.ReactNode }) {
  const { status, data: session } = useSession()
  const isLoadingSession = status === 'loading'

  useEffect(() => {
    if (isLoadingSession) return
    if (awsRum) return

    try {
      const config: AwsRumConfig = {
        telemetries: ['errors', 'http'],
        sessionSampleRate: 1, // 100%
        sessionEventLimit: 0, // no limit
        dispatchInterval: 1000,
        allowCookies: true,
        enableXRay: true,
        identityPoolId: RUM_IDENTITY_POOL_ID,
        endpoint: `https://dataplane.rum.${RUM_REGION}.amazonaws.com`,
      }

      awsRum = new AwsRum(RUM_ID, '1.0.0', RUM_REGION, config)

      if (session) {
        awsRum.addSessionAttributes({ userId: session.user.id, userName: session.user.name ?? '-' })
      }
    } catch (error) {
      console.error('Error initializing CloudWatch RUM web client', error)
    }
  }, [isLoadingSession, session])

  return children
}
