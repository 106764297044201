import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-_d652072591ef20de94dd3975a2f511ce/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-2_4574db2ce87a383081b41f7eab02128b/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-2_4574db2ce87a383081b41f7eab02128b/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProvider"] */ "/opt/atlassian/pipelines/agent/build/web/app/lib/providers/ClientProvider/ClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RumProvider"] */ "/opt/atlassian/pipelines/agent/build/web/app/lib/providers/RumProvider/RumProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SstStageProvider"] */ "/opt/atlassian/pipelines/agent/build/web/app/lib/providers/SstStageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Authentication"] */ "/opt/atlassian/pipelines/agent/build/web/app/lib/ui/Authentication/Authentication.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ResetDemoDataButton"] */ "/opt/atlassian/pipelines/agent/build/web/app/lib/ui/ResetDemoDataButton/ResetDemoDataButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSelector"] */ "/opt/atlassian/pipelines/agent/build/web/app/lib/ui/WebHeader/ClientSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebNavigation"] */ "/opt/atlassian/pipelines/agent/build/web/app/lib/ui/WebHeader/WebNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/opt/atlassian/pipelines/agent/build/web/app/trpc/TrpcProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/atlassian/pipelines/agent/build/web/components/ui/Toaster.tsx");
